import React, { useState } from "react";
import Carousell from "./Carousell";

let auth = false;

const checkInput = (input) => {
  if (btoa(input) === "a2FyZW5pbmE=") {
    auth = true;
  }
};

function Auth() {
  const [input, setInput] = useState(null);
  checkInput(input);

  return (
    <div className="Auth">
      {!auth && (
        <input
          type="password"
          onChange={(e) => setInput(e.target.value)}
        ></input>
      )}
      {auth && <Carousell></Carousell>}
    </div>
  );
}

export default Auth;
