import React from "react";
import { Carousel } from "react-bootstrap";

function Carousell() {
  const baseUrl = "https://fotosbodas.s3.eu-central-1.amazonaws.com";

  return (
    <Carousel interval={null} slide={false}>
      {[...Array(376)].map((_, i) => (
        <Carousel.Item key={i}>
          <img
            style={{ maxHeight: "100vh", maxWidth: "100vw" }}
            src={`${baseUrl}/${i}.jpg`}
            alt="imgs"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Carousell;
